import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  innerWidth: number;
  isCollapse?: boolean;
  permissionList: {
    _id: string;
    name: string;
    route: string;
    api: string;
  }[];
}

const initialState: InitialState = {
  innerWidth: 0,
  isCollapse: false,
  permissionList: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInnerWidth: (state, action) => {
      state.innerWidth = action.payload;
    },
    setSidebarCollapse: (state, action) => {
      state.isCollapse = action.payload;
    },
    setPermission: (state, action) => {
      state.permissionList = action.payload;
    },
  },
});

export const { setInnerWidth, setSidebarCollapse, setPermission } =
  appSlice.actions;
export default appSlice;
