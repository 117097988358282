import { createSlice } from "@reduxjs/toolkit";

import {
  RegionData,
  RegionInitialStatus,
} from "../../../types/redux/geo/region";
import {
  createRegionAsync,
  deleteRegionAsync,
  getAllRegionAsync,
  updateRegionAsync,
} from "./RegionApi";

const initialState: RegionInitialStatus<RegionData> = {
  loading: false,
  manageLoading: false,
  region: [],
  countRegion: 0,
};

const regionSlice = createSlice({
  name: "admin-Region",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createRegionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createRegionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createRegionAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllRegionAsync.pending, (state) => {
        state.loading = true;
        state.region = [];
      })
      .addCase(getAllRegionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.region = action.payload.data;
        state.countRegion = action.payload.length;
      })
      .addCase(getAllRegionAsync.rejected, (state) => {
        state.loading = false;
        state.region = [];
        state.countRegion = 0;
      });

    // update
    builder
      .addCase(updateRegionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateRegionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateRegionAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteRegionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteRegionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteRegionAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export default regionSlice;
