import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  GetSubCategoryData,
  SubCategoryData,
  SubCategoryDataParams,
} from "../../../types/redux/manageProduct/subCategory";

// create
export const createSubCategoryAsync = createAsyncThunk<
  null,
  PostRequest<SubCategoryData, CommonOthers>
>(
  "admin-SubCategory/createSubCategory",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createProductSubCategory, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Sub category create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Sub category create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllSubCategoryAsync = createAsyncThunk<
  GetCommonData<GetSubCategoryData>,
  Params<SubCategoryDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-SubCategory/getAllSubCategory",
  async ({ params }, { rejectWithValue }) => {
    const { permissionid, ...rest } = params as SubCategoryDataParams;

    try {
      const data = await AXIOS.get(BASE_API.allProductSubCategory, {
        params: { ...rest },
        headers: {
          permissionid,
        },
      });

      return {
        data: data.data?.categories,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get sub category fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateSubCategoryAsync = createAsyncThunk<
  null,
  PostRequest<SubCategoryData, GetSingleDataParams>
>(
  "admin-SubCategory/updateSubCategory",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateProductSubCategory}/${id}`, data, {
        headers: {
          permissionid,
        },
      });

      reRender && reRender(true);
      toast("success", "Sub category update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Sub category update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteSubCategoryAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>(
  "admin-SubCategory/deleteSubCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.deleteProductSubCategory}/${params?.id}`, {
        headers: {
          permissionid: params?.permissionid,
        },
      });

      params?.reRender && params?.reRender(true);
      toast("success", "Sub category delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Sub category delete fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singleSubCategoryAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-geo/singleSubCategory", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(
      `${BASE_API.singleProductSubCategory}/${params?.id}`,
      {
        headers: {
          permissionid: params?.permissionid,
        },
      }
    );

    return data.data?.categories as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single Sub Category data get fail");
    return rejectWithValue(error);
  }
});
