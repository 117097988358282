import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  DistrictData,
  DistrictDataParams,
} from "../../../types/redux/geo/district";

// create
export const createDistrictAsync = createAsyncThunk<
  null,
  PostRequest<Omit<DistrictData, "_id">, CommonOthers>
>(
  "admin-geo/createDistrict",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createDistrict, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "District create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "District create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllDistrictAsync = createAsyncThunk<
  GetCommonData<DistrictData>,
  Params<DistrictDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-geo/getAllDistrict", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as DistrictDataParams;

  try {
    const data = await AXIOS.get(BASE_API.allDistrict, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.districts,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get district fail");
    return rejectWithValue(error);
  }
});

// update
export const updateDistrictAsync = createAsyncThunk<
  null,
  PostRequest<DistrictData, GetSingleDataParams>
>(
  "admin-geo/updateDistrict",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateDistrict}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "District update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "District update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteDistrictAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-geo/deleteDistrict", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteDistrict}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "District delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "District delete fail");
    return rejectWithValue(error);
  }
});

// get single district
export const singDsitrictAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-geo/singDsitrictData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.viewDistrict}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    return data.data?.district as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single district data get fail");
    return rejectWithValue(error);
  }
});
