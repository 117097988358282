import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import { RegionData } from "../../../types/redux/geo/region";

// create
export const createRegionAsync = createAsyncThunk<
  null,
  PostRequest<RegionData, CommonOthers>
>(
  "admin-geo/createRegion",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createRegion, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Region create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Region create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllRegionAsync = createAsyncThunk<
  GetCommonData<RegionData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-geo/getAllRegion", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as CommonParams;

  try {
    const data = await AXIOS.get(BASE_API.allRegion, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.regions,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get region fail");
    return rejectWithValue(error);
  }
});

// update
export const updateRegionAsync = createAsyncThunk<
  null,
  PostRequest<RegionData, GetSingleDataParams>
>(
  "admin-geo/updateRegion",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateRegion}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Region update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Region update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteRegionAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-geo/deleteRegion", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteRegion}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "Region delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Region delete fail");
    return rejectWithValue(error);
  }
});
