import { createSlice } from "@reduxjs/toolkit";
import {
  GetProductReturnData,
  ProductReturnInitialStatus,
} from "../../../types/redux/productReturn/productReturn";
import {
  getAllProductReturnAsync,
  rejectReturnAsync,
  singProductReturnAsync,
  updateInventoryAsync,
  updateProductReturnAsync,
  updateSentWarehouseAsync,
} from "./ProductReturnApi";

const initialState: ProductReturnInitialStatus<GetProductReturnData> = {
  loading: false,
  manageLoading: false,
  viewLoading: false,
  returnData: [],
  singleReturnData: null,
  countReturnData: 0,
};

const productReturnSlice = createSlice({
  name: "admin-return",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all
    builder
      .addCase(getAllProductReturnAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProductReturnAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.returnData = action.payload.data;
        state.countReturnData = action.payload.length;
      })
      .addCase(getAllProductReturnAsync.rejected, (state) => {
        state.loading = false;
        state.returnData = [];
        state.countReturnData = 0;
      });

    // update
    builder
      .addCase(updateProductReturnAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateProductReturnAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateProductReturnAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // update
    builder
      .addCase(updateSentWarehouseAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateSentWarehouseAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateSentWarehouseAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // update
    builder
      .addCase(updateInventoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateInventoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateInventoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singProductReturnAsync.pending, (state) => {
        state.viewLoading = true;
        state.singleReturnData = null;
      })
      .addCase(singProductReturnAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleReturnData = action.payload;
      })
      .addCase(singProductReturnAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleReturnData = null;
      });

    // reject
    builder
      .addCase(rejectReturnAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(rejectReturnAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(rejectReturnAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export default productReturnSlice;
