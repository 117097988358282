import { createSlice } from "@reduxjs/toolkit";
import { ProductInitialStatus } from "../../../types/redux/manageProduct/product";
import {
  createProductAsync,
  deleteProductAsync,
  getAllProductAsync,
  singProductAsync,
  updateProductAsync,
} from "./ProductApi";

const initialState: ProductInitialStatus<any> = {
  loading: false,
  manageLoading: false,
  product: [],
  singleProductData: null,
  countProduct: 0,
};

const productSlice = createSlice({
  name: "admin-product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createProductAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createProductAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createProductAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllProductAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllProductAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.product = action.payload.data;
        state.countProduct = action.payload.length;
      })
      .addCase(getAllProductAsync.rejected, (state) => {
        state.loading = false;
        state.product = [];
        state.countProduct = 0;
      });

    // update
    builder
      .addCase(updateProductAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateProductAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateProductAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteProductAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteProductAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteProductAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singProductAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleProductData = null;
      })
      .addCase(singProductAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleProductData = action.payload;
      })
      .addCase(singProductAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleProductData = null;
      });
  },
});

export default productSlice;
