import { createSlice } from "@reduxjs/toolkit";

import { DistrictInitialStatus } from "../../../types/redux/geo/district";
import {
  createDistrictAsync,
  deleteDistrictAsync,
  getAllDistrictAsync,
  singDsitrictAsync,
  updateDistrictAsync,
} from "./DistrictApi";

const initialState: DistrictInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  district: [],
  countDistrict: 0,
  singleDistrictData: null,
};

const districtSlice = createSlice({
  name: "admin-district",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createDistrictAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createDistrictAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createDistrictAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllDistrictAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDistrictAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.district = action.payload.data;
        state.countDistrict = action.payload.length;
      })
      .addCase(getAllDistrictAsync.rejected, (state) => {
        state.loading = false;
        state.district = [];
        state.countDistrict = 0;
      });
    // get all
    builder
      .addCase(singDsitrictAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleDistrictData = null;
      })
      .addCase(singDsitrictAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleDistrictData = action.payload.data;
      })
      .addCase(singDsitrictAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleDistrictData = null;
      });

    // update
    builder
      .addCase(updateDistrictAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateDistrictAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateDistrictAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteDistrictAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteDistrictAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteDistrictAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export default districtSlice;
