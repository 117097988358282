export const BASE_API = {
  // Auth -(Account Management)
  login: "/auth/v1/login",
  passwordUpdate: "/auth/v1/pass-update",

  sbu: "/sbu",

  // dealer
  createDealer: "/dealer/v1/create",
  updateDealer: "/dealer/v1/update",
  allDealer: "/dealer/v1/data",
  singleDealer: "/dealer/v1/dealer-details",
  dealerStatusUpdate: "/dealer/v1/update-dealer-status",

  // Collateral
  bank: "/bank-guarantee",
  cheque: "/cheque",

  // Product Category
  createProductCategory: "/product-category/v1/create",
  updateProductCategory: "/product-category/v1/update",
  allProductCategory: "/product-category/v1/data",
  singleProductCategory: "/product-category/v1/details",
  deleteProductCategory: "/product-category/v1/delete",

  // Product sub Category
  createProductSubCategory: "/product-sub-category/v1/create",
  updateProductSubCategory: "/product-sub-category/v1/update",
  allProductSubCategory: "/product-sub-category/v1/data",
  singleProductSubCategory: "/product-sub-category/v1/details",
  deleteProductSubCategory: "/product-sub-category/v1/delete",

  // product
  createProduct: "/product/v1/create",
  updateProduct: "/product/v1/update",
  allProduct: "/product/v1/data",
  viewProduct: "/product/v1/details",
  deleteProduct: "/product/v1/delete",

  // Unit
  createUnit: "/unit/v1/create",
  updateUnit: "/unit/v1/update",
  allUnit: "/unit/v1/data",
  viewUnit: "/unit/v1/details",
  deleteUnit: "/unit/v1/delete",

  // ............Order ........
  order: "/order/v1",
  allOrder: "/order/v1/data",
  viewOrder: "/order/v1/order-details",
  updateOrder: "/order/v1/order-details/update",
  updateOrderItem: "/order/v1/order-product/update",
  orderSentToWarehouse: "/order/v1/sent-to-warehouse",
  orderCancelStatus: "/order/v1/cancel",
  orderSentToIcc: "/order/v1/sent-to-icc",
  orderSentToMd: "/order/v1/sent-to-md",
  orderSentToCo: "/order/v1/sent-to-co",
  orderSentToSalesAdmin: "/order/v1/sent-to-sales-admin",
  newOrderToWarehouse: "/order/v1/order-to-warehouse",
  orderUpdateWarehouse: "/app/order/v1/order-by-warehouse",
  getFromWarehouse: "/order/v1/item-orderlist/data",
  orderDeliveryConfirm: "/order/v1/confirm-delivery",
  assignGiftToOrder: "/order/v1/assign-gift-to-order",

  // .................GEO start..................

  // division
  createDivision: "/division/v1/create",
  updateDivision: "/division/v1/update",
  allDivision: "/division/v1/data",
  viewDivision: "/division/v1/details",
  deleteDivision: "/division/v1/delete",
  // District
  createDistrict: "/district/v1/create",
  updateDistrict: "/district/v1/update",
  allDistrict: "/district/v1/data",
  viewDistrict: "/district/v1/details",
  deleteDistrict: "/district/v1/delete",
  // police station
  createPoliceStation: "/police-station/v1/create",
  updatePoliceStation: "/police-station/v1/update",
  allPoliceStation: "/police-station/v1/data",
  viewPoliceStation: "/police-station/v1/details",
  deletePoliceStation: "/police-station/v1/delete",
  // Region
  createRegion: "/region/v1/create",
  updateRegion: "/region/v1/update",
  allRegion: "/region/v1/data",
  viewRegion: "/region/v1/details",
  deleteRegion: "/region/v1/delete",
  // Area
  createArea: "/area/v1/create",
  updateArea: "/area/v1/update",
  allArea: "/area/v1/data",
  viewArea: "/area/v1/details",
  deleteArea: "/area/v1/delete",
  // Territory
  createTerritory: "/territory/v1/create",
  updateTerritory: "/territory/v1/update",
  allTerritory: "/territory/v1/data",
  viewTerritory: "/territory/v1/details",
  deleteTerritory: "/territory/v1/delete",
  // .................GEO End..................

  //.................. gift manage ..................
  // gift-item
  createGiftItem: "/gift-item/v1/create",
  updateGiftItem: "/gift-item/v1/update",
  allGiftItem: "/gift-item/v1/data",
  viewGiftItem: "/gift-item/v1/details",
  deleteGiftItem: "/gift-item/v1/delete",

  // gift-assign
  createGiftAssign: "/gift-assign/v1/create",
  updateGiftAssign: "/gift-assign/v1/update",
  allGiftAssign: "/gift-assign/v1/data",
  viewGiftAssign: "/gift-assign/v1/details",
  deleteGiftAssign: "/gift-assign/v1/delete",
  getValidPackage: "/gift-assign/v1/get-valid-item-package",

  // warehouse
  createWarehouse: "/warehouse/v1/create",
  updateWarehouse: "/warehouse/v1/update",
  allWarehouse: "/warehouse/v1/data",
  viewWarehouse: "/warehouse/v1/details",
  deleteWarehouse: "/warehouse/v1/delete",

  //product return
  productReturn: "/product-return/v1",
  productReject: "/product-return/v1/reject",
  productDetails: "/product-return/v1/return-details",
  productReturnUpdate: "/product-return/v1/update",
  sentWarehouseUpdate: "/product-return/v1/sent-to-warehouse",
  inventory: "product-return/v1/inventory-received",

  targetSet: "/targetSet",
  srManage: "/srManage",
  appSettings: "/appSettings",

  // menu
  createMenu: "/menu/v1/create",
  updateMenu: "/menu/v1/update",
  allMenu: "/menu/v1/data",
  viewMenu: "/menu/v1/details",
  deleteMenu: "/menu/v1/delete",

  // role
  createRole: "/role/v1/create-role",
  updateRole: "/role/v1/update",
  allRole: "/role/v1/data",
  viewRole: "/role/v1/details",
  deleteRole: "/role/v1/delete",
  rolePermission: "/role/v1/get-permission/data",

  // user
  createUser: "/user/v1/create",
  updateUser: "/user/v1/update-user",
  allUser: "/user/v1/get-list",
  viewUser: "/user/v1/get-sinlge-user",
  deleteUser: "/user/v1/delete",

  // app slider
  createSlide: "/slide/v1/create",
  updateSlide: "/slide/v1/update",
  allSlide: "/slide/v1/data",
  viewSlide: "/slide/v1/details",
  deleteSlide: "/slide/v1/delete",

  // Bank list
  createBank: "/bank/v1/create",
  updateBank: "/bank/v1/update",
  allBank: "/bank/v1/data",
  viewBank: "/bank/v1/details",
  deleteBank: "/bank/v1/delete",

  // Payment
  allPayment: "/payment/v1/data",
  viewPayment: "/payment/v1/details",
  approvePayment: "/payment/v1/approved",
  rejectPayment: "/payment/v1/reject",
  allLedger: "/order/v1/ledger-details",

  // ledger
  ledgerDealer: "/order/v1/ledger-dealer-sbu-based-list",
  getLedgerByDealer: "/order/v1/ledger-details",

  // notification
  createNotification: "/user/v1/notification/create-notification",
  allNotification: "/user/v1/notification/get-all-notifications",

  // Image -(File Upload)
  fileUpload: `/dealer/v1/file-image-upload`,
};
