type Props = {
  img?: string;
  title: string;
  category: string;
  orderId: string;
};

export default function CustomNotification({
  img,
  title,
  category,
  orderId,
}: Props) {
  return (
    <div className="flex items-center gap-4">
      {img && (
        <div className="w-[72px] h-[72.40px] bg-[#ffa0a0]/20 rounded">
          <img
            src={img}
            alt=""
            className="w-full h-full object-cover rounded"
          />
        </div>
      )}

      <div className="flex-1 h-full">
        <h3 className="text-body text-base font-medium w-[calc(100%-22px)] whitespace-nowrap overflow-hidden text-ellipsis">
          {title}
        </h3>
        <h6 className="text-primary text-xs font-medium mt-1.5 mb-1 w-full whitespace-nowrap overflow-hidden text-ellipsis">
          {category}
        </h6>
        <span className="text-others-accent text-xs font-normal block w-full whitespace-nowrap overflow-hidden text-ellipsis">
          Order ID: {orderId}
        </span>
      </div>
    </div>
  );
}
