import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  GetLedgerData,
  LedgerDataParams,
  PaymentDataParams,
} from "../../../types/redux/payment/payment";

// get all payment
export const getAllPaymentAsync = createAsyncThunk<
  GetCommonData<any>,
  Params<PaymentDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-dealerPayment/getAllPayment",
  async ({ params }, { rejectWithValue }) => {
    const { permissionid, ...rest } = params as PaymentDataParams;

    try {
      const data = await AXIOS.get(BASE_API.allPayment, {
        params: { ...rest },
        headers: { permissionid },
      });

      return {
        data: data.data?.payments,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get payment fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singlePaymentAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>(
  "admin-dealerPayment/singlePayment",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.viewPayment}/${params?.id}`, {
        headers: { permissionid: params?.permissionid },
      });

      return data.data?.payment as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get single payment fail");
      return rejectWithValue(error);
    }
  }
);

// approve payment
export const approvePaymentAsync = createAsyncThunk<
  null,
  PostRequest<undefined, GetSingleDataParams>
>(
  "admin-dealerPayment/approvePayment",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.approvePayment}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Payment approve successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Payment approve fail");
      return rejectWithValue(error);
    }
  }
);

// reject payment
export const rejectPaymentAsync = createAsyncThunk<
  null,
  PostRequest<undefined, GetSingleDataParams>
>(
  "admin-dealerPayment/rejectPayment",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.rejectPayment}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Payment reject successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Payment reject fail");
      return rejectWithValue(error);
    }
  }
);

// get all Ledger
export const getAllLedgerAsync = createAsyncThunk<
  GetLedgerData,
  Params<LedgerDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-dealerPayment/getAllLedger",
  async ({ params }, { rejectWithValue }) => {
    const { permissionid, ...rest } = params as LedgerDataParams;

    try {
      const data = await AXIOS.get(BASE_API.allLedger, {
        params: { ...rest },
        headers: { permissionid },
      });

      return {
        data: data.data?.getData,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get ledger fail");
      return rejectWithValue(error);
    }
  }
);
