import { lazy } from "react";

import { RouteType } from "../types";

const OpenLedger = lazy(() => import("../pages/admin/LedgerReport/OpenLedger"));
const ItemOrdersManage = lazy(
  () => import("../pages/admin/OrdersManage/ItemOrdersManage")
);
const BankList = lazy(() => import("../pages/admin/Bank/BankList"));
const AppSlider = lazy(() => import("../pages/admin/AppSlider/AppSlider"));
const AppSettings = lazy(
  () => import("../pages/admin/APPSettings/AppSettings")
);
const Menu = lazy(() => import("../pages/admin/User/Menu"));
const ProductReturn = lazy(() => import("../pages/admin/Return/ProductReturn"));
const SrManage = lazy(() => import("../pages/admin/SR/SrManage"));
const TargetSet = lazy(() => import("../pages/admin/Target/TargetSet"));
const AddNewUser = lazy(
  () => import("../components/admin/ManageUser/AddNewUser")
);
const RolePermission = lazy(
  () => import("../components/admin/ManageUser/RolePermission")
);
const CreateUser = lazy(() => import("../pages/admin/User/CreateUser"));
const CreateRole = lazy(() => import("./../pages/admin/User/CreateRole"));
const Area = lazy(() => import("../pages/admin/Geo/Area"));
const District = lazy(() => import("../pages/admin/Geo/District"));
const Division = lazy(() => import("../pages/admin/Geo/Division"));
const PoliceStation = lazy(() => import("../pages/admin/Geo/PoliceStation"));
const Region = lazy(() => import("../pages/admin/Geo/Region"));
const Territory = lazy(() => import("../pages/admin/Geo/Territory"));
const NotFound = lazy(() => import("../pages/NotFound"));
const AdminLogin = lazy(() => import("../pages/admin/Login"));
const AdminDashboard = lazy(() => import("../pages/admin/Dashboard"));
const Cheque = lazy(() => import("../pages/admin/Collateral/Cheque"));
const Category = lazy(() => import("../pages/admin/ProductsManage/Category"));
const Products = lazy(() => import("../pages/admin/ProductsManage/Products"));
const Units = lazy(() => import("../pages/admin/ProductsManage/Units"));
const GiftItems = lazy(() => import("../pages/admin/GiftManage/GiftItems"));
const GiftAssign = lazy(() => import("../pages/admin/GiftManage/GiftAssign"));
const LedgerReport = lazy(
  () => import("../pages/admin/LedgerReport/LedgerReport")
);
const Settings = lazy(() => import("../pages/admin/Settings/Settings"));
const OrderRequest = lazy(
  () => import("../pages/admin/WearHouse/OrderRequest")
);
const OrderRequestDetails = lazy(
  () => import("../pages/admin/WearHouse/OrderRequestDetails")
);
const CreateWearHouse = lazy(
  () => import("../pages/admin/WearHouse/CreateWearHouse")
);
const CreateDealer = lazy(
  () => import("../pages/admin/DealersManage/CreateDealer")
);
const ViewDealer = lazy(
  () => import("../pages/admin/DealersManage/ViewDealer")
);
const DealersManage = lazy(
  () => import("../pages/admin/DealersManage/DealersManage")
);
const BankGuarantee = lazy(
  () => import("../pages/admin/Collateral/BankGuarantee")
);
const OrdersManage = lazy(
  () => import("../pages/admin/OrdersManage/OrdersManage")
);
const OrderDetails = lazy(
  () => import("../pages/admin/OrdersManage/OrderDetails")
);
const SubCategory = lazy(
  () => import("../pages/admin/ProductsManage/SubCategory")
);
const Notification = lazy(
  () => import("../pages/admin/Notification/Notification")
);
const DealerPayment = lazy(
  () => import("../pages/admin/DealerPayment/DealerPayment")
);

// auth routers
export const authRouters: RouteType[] = [
  {
    path: "/admin/dashboard",
    element: AdminDashboard,
  },

  // Dealer management
  {
    path: "/admin/dealers-manage",
    element: DealersManage,
  },
  {
    path: "/admin/dealers-manage/create-dealer",
    element: CreateDealer,
  },

  {
    path: "/admin/dealers-manage/update-dealer/:id",
    element: CreateDealer,
  },
  {
    path: "/admin/dealers-manage/view-dealer/:id",
    element: ViewDealer,
  },

  //Collateral
  {
    path: "/admin/collateral/bank-guarantee",
    element: BankGuarantee,
  },
  {
    path: "/admin/collateral/cheque",
    element: Cheque,
  },

  // Products manage
  {
    path: "/admin/products-manage/category",
    element: Category,
  },
  {
    path: "/admin/products-manage/sub-category",
    element: SubCategory,
  },
  {
    path: "/admin/products-manage/products",
    element: Products,
  },
  {
    path: "/admin/products-manage/units",
    element: Units,
  },

  // Gift management
  {
    path: "/admin/gift-manage/gift-items",
    element: GiftItems,
  },
  {
    path: "/admin/gift-manage/gift-assign",
    element: GiftAssign,
  },

  // Order management
  {
    path: "/admin/orders-manage/order-list",
    element: OrdersManage,
  },
  {
    path: "/admin/orders-manage/order-list/view/:id",
    element: OrderDetails,
  },
  {
    path: "/admin/orders-manage/item-order-list",
    element: ItemOrdersManage,
  },
  // Return
  {
    path: "/admin/product-return",
    element: ProductReturn,
  },
  // Target
  {
    path: "/admin/target-set",
    element: TargetSet,
  },

  // Wear house
  {
    path: "/admin/manage-warehouse/warehouse",
    element: CreateWearHouse,
  },
  {
    path: "/admin/manage-warehouse/order-request",
    element: OrderRequest,
  },
  {
    path: "/admin/manage-warehouse/order-request/view/:orderId",
    element: OrderRequestDetails,
  },

  // notification
  {
    path: "/admin/notification",
    element: Notification,
  },

  // ledger report
  {
    path: "/admin/ledger-report",
    element: LedgerReport,
  },
  {
    path: "/admin/ledger-report/:id",
    element: OpenLedger,
  },

  //GEO
  {
    path: "/admin/geo/division",
    element: Division,
  },
  {
    path: "/admin/geo/district",
    element: District,
  },
  {
    path: "/admin/geo/police-station",
    element: PoliceStation,
  },
  {
    path: "/admin/geo/region",
    element: Region,
  },
  {
    path: "/admin/geo/area",
    element: Area,
  },
  {
    path: "/admin/geo/territory",
    element: Territory,
  },

  // sr manage
  {
    path: "/admin/sr-manage",
    element: SrManage,
  },

  // app settings
  {
    path: "/admin/app-settings",
    element: AppSettings,
  },

  // user
  {
    path: "/admin/user-manage/user",
    element: CreateUser,
  },
  {
    path: "/admin/user-manage/user/add-new-user",
    element: AddNewUser,
  },
  {
    path: "/admin/user-manage/user/update-user/:id",
    element: AddNewUser,
  },
  {
    path: "/admin/user-manage/user/view-user/:viewId",
    element: AddNewUser,
  },
  {
    path: "/admin/user-manage/role",
    element: CreateRole,
  },
  {
    path: "/admin/user-manage/role/role-permission",
    element: RolePermission,
  },

  {
    path: "/admin/user-manage/role/update-role-permission/:id",
    element: RolePermission,
  },
  {
    path: "/admin/user-manage/role/view-role-permission/:viewId",
    element: RolePermission,
  },
  {
    path: "/admin/user-manage/menu",
    element: Menu,
  },

  // app settings
  {
    path: "/admin/app-slider",
    element: AppSlider,
  },
  // bank manage
  {
    path: "/admin/bank",
    element: BankList,
  },

  // settings
  {
    path: "/admin/settings",
    element: Settings,
  },
  {
    path: "/admin/dealer-payment",
    element: DealerPayment,
  },
];

// public routers
export const publicRouters: RouteType[] = [
  {
    path: "/admin/login",
    element: AdminLogin,
  },
  {
    path: "404",
    element: NotFound,
  },
  {
    path: "*",
    element: NotFound,
  },
];
