import { createSlice } from "@reduxjs/toolkit";
import { ProductCategoryInitialStatus } from "../../../types/redux/manageProduct/productCategory";

import {
  createProductCategoryAsync,
  deleteProductCategoryAsync,
  getAllProductCategoryAsync,
  singleProductCategoryAsync,
  updateProductCategoryAsync,
} from "./ProductCategoryApi";

const initialState: ProductCategoryInitialStatus<any> = {
  loading: false,
  manageLoading: false,
  category: [],
  singleCategoryData: null,
  countCategory: 0,
};

const productCategorySlice = createSlice({
  name: "admin-productCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createProductCategoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createProductCategoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createProductCategoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllProductCategoryAsync.pending, (state) => {
        state.loading = true;
        state.category = [];
      })
      .addCase(getAllProductCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.category = action.payload.data;
        state.countCategory = action.payload.length;
      })
      .addCase(getAllProductCategoryAsync.rejected, (state) => {
        state.loading = false;
        state.category = [];
        state.countCategory = 0;
      });

    // update
    builder
      .addCase(updateProductCategoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateProductCategoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateProductCategoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteProductCategoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteProductCategoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteProductCategoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singleProductCategoryAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleCategoryData = null;
      })
      .addCase(singleProductCategoryAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleCategoryData = action.payload;
      })
      .addCase(singleProductCategoryAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleCategoryData = null;
      });
  },
});

export default productCategorySlice;
