import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { AUTH_SAVE } from "../../../helpers/config";
import { toast } from "../../../helpers/toast";
import {
  AuthUser,
  CommonOthers,
  ErrorMessage,
  LoginActionType,
  LoginData,
  PostRequest,
  UpdatePasswordParams,
} from "../../../types/redux";

// login user
export const loginUserAsync = createAsyncThunk<
  LoginActionType<AuthUser>,
  PostRequest<LoginData, CommonOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "login/loginUser",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      // const loginData = await AXIOS.post(BASE_API.login, data, {
      //   baseURL: "https://staging-edatco-api.xelerator.org",
      // });
      const loginData = await AXIOS.post(BASE_API.login, data);

      const responseData = {
        token: loginData?.data?.token,
        data: loginData?.data?.findUser,
        siteRole: "ADMIN",
      };

      if (responseData?.token && responseData?.data?.email) {
        AXIOS.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${responseData?.token}`;

        localStorage.setItem(`${AUTH_SAVE}`, JSON.stringify(responseData));
      }

      reRender(true);
      toast("success", "Login successfully");

      return responseData as LoginActionType<AuthUser>;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Login fail");
      return rejectWithValue(error);
    }
  }
);

// update password
export const updatePasswordAsync = createAsyncThunk<
  null,
  PostRequest<UpdatePasswordParams, CommonOthers>
>(
  "login/passwordUpdate",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.patch(BASE_API.passwordUpdate, data, {
        headers: { permissionid },
      });

      toast("success", "Password change successfully");
      reRender && reRender(true);
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Password change fail");
      return rejectWithValue(error);
    }
  }
);
