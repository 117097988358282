import { createSlice } from "@reduxjs/toolkit";
import {
  OrderInitialStatus,
  OrdersData,
} from "../../../types/redux/order/order";
import {
  getAllOrderAsync,
  getFromWarehouseAsync,
  giftToOrderAsync,
  newOrderToWarehouseAsync,
  orderCancelStatusAsync,
  orderDeliveryConfirmAsync,
  orderSentToCoAsync,
  orderSentToIccAsync,
  orderSentToMdAsync,
  orderSentToSalesAdminAsync,
  orderSentToWarehouseStatusAsync,
  orderUpdateWarehouseAsync,
  singleOrderDataAsync,
  updateOrderAsync,
  updateOrderItemAsync,
} from "./OrderApi";

const initialState: OrderInitialStatus<OrdersData, any, any> = {
  loading: false,
  manageLoading: false,
  cancelLoading: false,
  forwardLoading: false,
  viewLoading: false,
  warehouseOrderData: [],
  order: [],
  singleOrderData: null,
  countOrder: 0,
};

const orderSlice = createSlice({
  name: "admin-order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all
    builder
      .addCase(getAllOrderAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllOrderAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload.data;
        state.countOrder = action.payload.length || 0;
      })
      .addCase(getAllOrderAsync.rejected, (state) => {
        state.loading = false;
        state.order = [];
        state.countOrder = 0;
      });

    // update price
    builder
      .addCase(updateOrderItemAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateOrderItemAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateOrderItemAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // update price
    builder
      .addCase(updateOrderAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateOrderAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateOrderAsync.rejected, (state) => {
        state.manageLoading = false;
      });
    // update price
    builder
      .addCase(orderSentToWarehouseStatusAsync.pending, (state) => {
        state.forwardLoading = true;
      })
      .addCase(orderSentToWarehouseStatusAsync.fulfilled, (state) => {
        state.forwardLoading = false;
      })
      .addCase(orderSentToWarehouseStatusAsync.rejected, (state) => {
        state.forwardLoading = false;
      });
    builder
      .addCase(orderCancelStatusAsync.pending, (state) => {
        state.cancelLoading = true;
      })
      .addCase(orderCancelStatusAsync.fulfilled, (state) => {
        state.cancelLoading = false;
      })
      .addCase(orderCancelStatusAsync.rejected, (state) => {
        state.cancelLoading = false;
      });
    builder
      .addCase(orderSentToIccAsync.pending, (state) => {
        state.forwardLoading = true;
      })
      .addCase(orderSentToIccAsync.fulfilled, (state) => {
        state.forwardLoading = false;
      })
      .addCase(orderSentToIccAsync.rejected, (state) => {
        state.forwardLoading = false;
      });
    builder
      .addCase(orderSentToMdAsync.pending, (state) => {
        state.forwardLoading = true;
      })
      .addCase(orderSentToMdAsync.fulfilled, (state) => {
        state.forwardLoading = false;
      })
      .addCase(orderSentToMdAsync.rejected, (state) => {
        state.forwardLoading = false;
      });
    builder
      .addCase(orderSentToCoAsync.pending, (state) => {
        state.forwardLoading = true;
      })
      .addCase(orderSentToCoAsync.fulfilled, (state) => {
        state.forwardLoading = false;
      })
      .addCase(orderSentToCoAsync.rejected, (state) => {
        state.forwardLoading = false;
      });
    builder
      .addCase(orderSentToSalesAdminAsync.pending, (state) => {
        state.forwardLoading = true;
      })
      .addCase(orderSentToSalesAdminAsync.fulfilled, (state) => {
        state.forwardLoading = false;
      })
      .addCase(orderSentToSalesAdminAsync.rejected, (state) => {
        state.forwardLoading = false;
      });
    builder
      .addCase(newOrderToWarehouseAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(newOrderToWarehouseAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(newOrderToWarehouseAsync.rejected, (state) => {
        state.manageLoading = false;
      });
    builder
      .addCase(orderUpdateWarehouseAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(orderUpdateWarehouseAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(orderUpdateWarehouseAsync.rejected, (state) => {
        state.manageLoading = false;
      });
    builder
      .addCase(orderDeliveryConfirmAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(orderDeliveryConfirmAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(orderDeliveryConfirmAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // gift to order
    builder
      .addCase(giftToOrderAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(giftToOrderAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(giftToOrderAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(getFromWarehouseAsync.pending, (state) => {
        state.loading = true;
        state.warehouseOrderData = [];
      })
      .addCase(getFromWarehouseAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.warehouseOrderData = action.payload;
      })
      .addCase(getFromWarehouseAsync.rejected, (state) => {
        state.loading = false;
        state.warehouseOrderData = [];
      });
    // single
    builder
      .addCase(singleOrderDataAsync.pending, (state) => {
        state.viewLoading = true;
        state.singleOrderData = null;
      })
      .addCase(singleOrderDataAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleOrderData = action.payload;
      })
      .addCase(singleOrderDataAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleOrderData = null;
      });
  },
});

export default orderSlice;
