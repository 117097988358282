import { useAppSelector } from "../store/store";

export default function useSbu() {
  const { login } = useAppSelector((state) => state.login);
  const { sbuData } = useAppSelector((state) => state.sbu);

  return sbuData?.filter((item) =>
    login?.sbuIds?.some((sbu) => sbu.sbuId === item?._id)
  );
}
