import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  UnitData,
  UnitDataParams,
} from "../../../types/redux/manageProduct/unit";

// create
export const createUnitAsync = createAsyncThunk<
  null,
  PostRequest<Omit<UnitData, "_id">, CommonOthers>
>(
  "admin-geo/createUnit",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createUnit, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Unit create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Unit create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllUnitAsync = createAsyncThunk<
  GetCommonData<UnitData>,
  Params<UnitDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-geo/getAllUnit", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as UnitDataParams;

  try {
    const data = await AXIOS.get(BASE_API.allUnit, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.unites,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get unit fail");
    return rejectWithValue(error);
  }
});

// update
export const updateUnitAsync = createAsyncThunk<
  null,
  PostRequest<UnitData, GetSingleDataParams>
>(
  "admin-geo/updateUnit",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateUnit}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Unit update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Unit update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteUnitAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-geo/deleteUnit", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteUnit}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "Unit delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Unit delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singUnitAsync = createAsyncThunk<any, Params<GetSingleDataParams>>(
  "admin-geo/singUnitData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.viewUnit}/${params?.id}`, {
        headers: { permissionid: params?.permissionid },
      });

      return data.data?.unites as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single unit data get fail");
      return rejectWithValue(error);
    }
  }
);
