import { Badge, Button, Dropdown } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import NotificationHeaderIcon from "../../../assets/icons/NotificationHeaderIcon";
import { IMAGE_URL } from "../../../helpers/config";
import { IMG } from "../../../helpers/siteConfig";
import { useAppSelector } from "../../../store/store";
import EmptyComponent from "../EmptyComponent";
import Loader from "../Loader";

export default function NotificationMenu() {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { notifications, loading } = useAppSelector(
    (state) => state.notification
  );

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      open={openDropdown}
      onOpenChange={setOpenDropdown}
      dropdownRender={() => (
        <div className="w-64 bg-white shadow rounded-md">
          <div className="flex items-center justify-between gap-2 py-1.5 px-3 shadow-sm shadow-gray-100 sticky top-0">
            <span className="text-xs text-header font-medium">
              Notifications
            </span>
            <Link
              className="text-xs text-body font-medium hover:text-header"
              to="/admin/notification"
              onClick={() => setOpenDropdown(false)}
            >
              View All
            </Link>
          </div>

          <div>
            <div className="h-48 overflow-y-auto notification">
              {loading ? (
                <Loader />
              ) : notifications?.length ? (
                notifications?.map((el) => (
                  <div
                    className="border border-solid border-others-border border-b-0 border-x-0 first:border-t-0 border-t-[1px]"
                    key={el?._id}
                  >
                    <div className="grid grid-cols-80 gap-1.5 py-1.5 pl-1.5">
                      <div className="flex items-center justify-center">
                        <img
                          className="w-10 h-10 object-cover rounded-full"
                          src={
                            el?.user?.image ? IMAGE_URL + el?.user?.image : IMG
                          }
                          alt=""
                        />
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="relative -top-0.5">
                          <h6 className="text-body text-base font-medium leading-tight break-all mb-1">
                            {el?.user?.fullName || (
                              <span className="text-body/50">User</span>
                            )}
                          </h6>

                          <div className="text-[#7d88f4] text-sm font-normal leading-normal break-all">
                            {el?.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyComponent
                  rootClassName="pt-10"
                  imageStyle={{ height: 100 }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    >
      <Badge count={notifications?.length}>
        <Button
          className="border-none shadow-sm"
          icon={<NotificationHeaderIcon />}
          size="middle"
        />
      </Badge>
    </Dropdown>
  );
}
