import { createSlice } from "@reduxjs/toolkit";

import {
  EmptyDataRolePermission,
  GetRolePermissionData,
  RolePermissionInitialStatus,
} from "../../../../types/redux/user/rolePermission";
import {
  createRolePermissionAsync,
  deleteRolePermissionAsync,
  getAllRolePermissionAsync,
  getPermissionAsync,
  singleAllRolePermissionAsync,
  updateRolePermissionAsync,
} from "./RolePermissionApi";

const initialState: RolePermissionInitialStatus<GetRolePermissionData, any> = {
  loading: false,
  manageLoading: false,
  singleLoading: false,
  roles: [],
  permissions: [],
  countRole: 0,
  singleRoleData: null,
  singleSidebarLoading: false,
  singleSidebarRoleData: null,
};

const rolePermissionSlice = createSlice({
  name: "admin-role-permission",
  initialState,
  reducers: {
    emptyData(state, action: EmptyDataRolePermission) {
      state[action?.payload?.name] = action?.payload?.value;
    },
  },
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createRolePermissionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createRolePermissionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createRolePermissionAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllRolePermissionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllRolePermissionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload?.data;
        state.countRole = action.payload.length;
      })
      .addCase(getAllRolePermissionAsync.rejected, (state) => {
        state.loading = false;
        state.roles = [];
        state.countRole = 0;
      });
    // get permission
    builder
      .addCase(getPermissionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPermissionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload?.data;
      })
      .addCase(getPermissionAsync.rejected, (state) => {
        state.loading = false;
        state.permissions = [];
      });
    // get single
    builder
      .addCase(singleAllRolePermissionAsync.pending, (state, action) => {
        if (action.meta.arg.params?.isSidebar) {
          state.singleSidebarLoading = true;
          state.singleSidebarRoleData = null;
        } else {
          state.singleLoading = true;
          state.singleRoleData = null;
        }
      })
      .addCase(singleAllRolePermissionAsync.fulfilled, (state, action) => {
        if (action.meta.arg.params?.isSidebar) {
          state.singleSidebarLoading = false;
          state.singleSidebarRoleData = action.payload || undefined;
        } else {
          state.singleLoading = false;
          state.singleRoleData = action.payload;
        }
      })
      .addCase(singleAllRolePermissionAsync.rejected, (state, action) => {
        if (action.meta.arg.params?.isSidebar) {
          state.singleSidebarLoading = false;
          state.singleSidebarRoleData = undefined;
        } else {
          state.singleLoading = false;
          state.singleRoleData = null;
        }
      });

    // update
    builder
      .addCase(updateRolePermissionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateRolePermissionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateRolePermissionAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteRolePermissionAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteRolePermissionAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteRolePermissionAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export const { emptyData } = rolePermissionSlice.actions;

export default rolePermissionSlice;
