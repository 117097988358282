import { Fragment, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/common/Loader";
import usePermissionId from "../hooks/usePermissionId";
import { setPermission } from "../store/features/appSlice";
import { getAllMenuAsync } from "../store/features/UserManage/Menu/MenuApi";
import { useAppDispatch, useAppSelector } from "../store/store";

type Props = {
  children: React.ReactNode;
  path: string;
};

export default function AuthMiddleware({ children, path }: Props) {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // const [systemLoading, setSystemLoading] = useState(true);
  // const [searchParams] = useSearchParams();
  const { sidebarLoading } = useAppSelector((state) => state.menu);
  const { singleSidebarLoading, singleSidebarRoleData } = useAppSelector(
    (state) => state.rolePermission
  );
  const { token, login, loading } = useAppSelector((state) => state.login);
  const permissionId = usePermissionId("/admin/user-manage/menu");

  // useEffect(() => {
  //   if (login?.roleId) {
  //     dispatch(
  //       singleAllRolePermissionAsync({
  //         params: {
  //           id: login?.roleId || "",
  //           isSidebar: true,
  //           permissionid: "",
  //         },
  //       })
  //     );
  //   }
  // }, [dispatch, login?.roleId]);

  useEffect(() => {
    //  && permissionId
    if (login?.roleId) {
      dispatch(
        getAllMenuAsync({
          params: {
            pageNo: 0,
            limit: 0,
            isSidebar: true,
            permissionid: permissionId,
          },
        })
      );
    }
  }, [dispatch, login?.roleId, permissionId]);

  // useEffect(() => {
  //   const route = singleSidebarRoleData?.rolePermission?.[0]?.route;
  //   if (route && searchParams.get("from") === "login") {
  //     navigate(route);
  //   }

  //   const timer = setTimeout(() => {
  //     setSystemLoading(false);
  //   }, 500);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [navigate, searchParams, singleSidebarRoleData?.rolePermission]);

  useEffect(() => {
    const rolePermission = singleSidebarRoleData?.rolePermission;

    if (rolePermission?.length) {
      dispatch(setPermission(rolePermission));
    }
  }, [dispatch, singleSidebarRoleData?.rolePermission]);

  if (loading || sidebarLoading || singleSidebarLoading) {
    return <Loader />;
  }

  if (loading || singleSidebarRoleData === null) {
    return <Loader />;
  }

  const accessRoute = singleSidebarRoleData?.rolePermission?.find(
    (item: any) => item.route === path.split("/:")[0]
  );

  return token && login?.email ? (
    accessRoute ? (
      <Fragment>{children}</Fragment>
    ) : (
      <Navigate to="/admin/login" replace />
    )
  ) : (
    <Navigate to={"/admin/login"} replace />
  );
}
