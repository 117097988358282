import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonParams,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  GetProductReturnData,
  GetProductReturnDataParams,
} from "../../../types/redux/productReturn/productReturn";

// get all
export const getAllProductReturnAsync = createAsyncThunk<
  GetCommonData<GetProductReturnData>,
  Params<GetProductReturnDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-return/getAllProductReturn",
  async ({ params }, { rejectWithValue }) => {
    const { permissionid, ...rest } = params as CommonParams;

    try {
      const data = await AXIOS.get(`${BASE_API.productReturn}/data`, {
        params: { ...rest },
        headers: {
          permissionid,
        },
      });

      return {
        data: data.data?.returns,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get product return fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateProductReturnAsync = createAsyncThunk<
  null,
  PostRequest<any, any>
>(
  "admin-return/updateProductReturn",
  async (
    { data, others: { reRender, id, permissionid, sbuid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.productReturnUpdate}/${id}`, data, {
        headers: { permissionid, sbuid },
      });

      reRender && reRender(true);
      toast("success", "Update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Update fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateSentWarehouseAsync = createAsyncThunk<
  null,
  PostRequest<any, any>
>(
  "admin-return/updateSentWarehouse",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.sentWarehouseUpdate}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Update fail");
      return rejectWithValue(error);
    }
  }
);

// update inventory
export const updateInventoryAsync = createAsyncThunk<
  null,
  PostRequest<any, any>
>(
  "admin-return/updateInventory",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.inventory}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Update fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singProductReturnAsync = createAsyncThunk<any, Params<any>>(
  "admin-return/singProductReturn",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.productDetails}/${params?.id}`, {
        headers: { permissionid: params?.permissionid },
      });
      console.log(data);

      return data.data?.returnRetails as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "data get fail");
      return rejectWithValue(error);
    }
  }
);

// reject payment
export const rejectReturnAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-return/rejectReturn",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.productReject}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "reject successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "reject fail");
      return rejectWithValue(error);
    }
  }
);
