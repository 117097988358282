import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import { AreaData, AreaDataParams } from "../../../types/redux/geo/area";

// create
export const createAreaAsync = createAsyncThunk<
  null,
  PostRequest<AreaData, CommonOthers>
>(
  "admin-geo/createArea",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createArea, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Area create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Area create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllAreaAsync = createAsyncThunk<
  GetCommonData<AreaData>,
  Params<AreaDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-geo/getAllArea", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as AreaDataParams;

  try {
    const data = await AXIOS.get(BASE_API.allArea, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.areas,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Area fail");
    return rejectWithValue(error);
  }
});

// update
export const updateAreaAsync = createAsyncThunk<
  null,
  PostRequest<AreaData, GetSingleDataParams>
>(
  "admin-geo/updateArea",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateArea}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Area update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Area update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteAreaAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-geo/deleteArea", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteArea}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "Area delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Area delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singAreaAsync = createAsyncThunk<any, Params<GetSingleDataParams>>(
  "admin-geo/singAreaData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.viewArea}/${params?.id}`, {
        headers: { permissionid: params?.permissionid },
      });

      return data.data?.areas as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single areas data get fail");
      return rejectWithValue(error);
    }
  }
);
