import { createSlice } from "@reduxjs/toolkit";
import { LedgerInitialStatus } from "../../../types/redux/ledger/ledger";
import { getLedgerByDealerAsync, ledgerDealerAsync } from "./LedgerApi";

const initialState: LedgerInitialStatus<any, any> = {
  loading: false,
  manageLoading: false,
  ledgerDealers: [],
  countLedgerDealer: 0,
  ledger: null,
  countLedger: 0,
};

const ledgerSlice = createSlice({
  name: "admin-ledger",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ledgerDealerAsync.pending, (state) => {
        state.loading = true;
        state.ledgerDealers = [];
        state.countLedgerDealer = 0;
      })
      .addCase(ledgerDealerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.ledgerDealers = action.payload.data;
        state.countLedgerDealer = action.payload.length;
      })
      .addCase(ledgerDealerAsync.rejected, (state) => {
        state.loading = false;
        state.ledgerDealers = [];
        state.countLedgerDealer = 0;
      });
    builder
      .addCase(getLedgerByDealerAsync.pending, (state) => {
        state.loading = true;
        state.ledger = null;
        state.countLedger = 0;
      })
      .addCase(getLedgerByDealerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.ledger = action.payload.data;
        state.countLedger = action.payload.length;
      })
      .addCase(getLedgerByDealerAsync.rejected, (state) => {
        state.loading = false;
        state.ledger = null;
        state.countLedger = 0;
      });
  },
});

export default ledgerSlice;
