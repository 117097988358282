import { createSlice } from "@reduxjs/toolkit";
import {
  GetSubCategoryData,
  SubCategoryInitialStatus,
} from "../../../types/redux/manageProduct/subCategory";
import {
  createSubCategoryAsync,
  deleteSubCategoryAsync,
  getAllSubCategoryAsync,
  singleSubCategoryAsync,
  updateSubCategoryAsync,
} from "./SubCategoryApi";

const initialState: SubCategoryInitialStatus<GetSubCategoryData> = {
  loading: false,
  manageLoading: false,
  subCategory: [],
  singleSubCategoryData: null,
  countSubCategory: 0,
};

const subCategorySlice = createSlice({
  name: "admin-SubCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createSubCategoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createSubCategoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createSubCategoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllSubCategoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSubCategoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.subCategory = action.payload.data;
        state.countSubCategory = action.payload.length;
      })
      .addCase(getAllSubCategoryAsync.rejected, (state) => {
        state.loading = false;
        state.subCategory = [];
        state.countSubCategory = 0;
      });

    // update
    builder
      .addCase(updateSubCategoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateSubCategoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateSubCategoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteSubCategoryAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteSubCategoryAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteSubCategoryAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singleSubCategoryAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleSubCategoryData = null;
      })
      .addCase(singleSubCategoryAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleSubCategoryData = action.payload;
      })
      .addCase(singleSubCategoryAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleSubCategoryData = null;
      });
  },
});

export default subCategorySlice;
