import { Avatar, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { IMG } from "../../../helpers/siteConfig";
import usePermissionId from "../../../hooks/usePermissionId";
import { userLogout } from "../../../store/features/auth/loginSlice";
import { useAppDispatch } from "../../../store/store";

export default function ProfileMenu() {
  const dispatch = useAppDispatch();
  const profilePermission = usePermissionId("/admin/settings");

  return (
    <Dropdown
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      className="cursor-pointer"
      rootClassName="profile-menu !left-auto !right-5"
      dropdownRender={() => (
        <ul className="list-none bg-white shadow p-1 rounded">
          {profilePermission ? (
            <li>
              <Link
                to="/admin/settings"
                className="py-1.5 px-8 cursor-pointer select-none text-body hover:text-primary hover:bg-[#FFF5F4] text-sm rounded block"
              >
                Profile
              </Link>
            </li>
          ) : null}
          <li
            onClick={() => dispatch(userLogout())}
            className="py-1.5 px-8 cursor-pointer select-none text-body hover:text-primary hover:bg-[#FFF5F4] text-sm rounded"
          >
            Logout
          </li>
        </ul>
      )}
    >
      <div className="flex items-center gap-2.5 cursor-pointer">
        <Avatar size={44} src={IMG} />
        {/* <span className="text-[#555b6d] text-base font-medium flex items-center">
          {login?.firstName} <BottomDropdownIcon />
        </span> */}
      </div>
    </Dropdown>
  );
}
