import { createSlice } from "@reduxjs/toolkit";
import { GiftItemInitialStatus } from "../../../../types/redux/gift/giftItem";
import {
  createGiftItemAsync,
  deleteGiftItemAsync,
  getAllGiftItemAsync,
  singGiftItemAsync,
  updateGiftItemAsync,
} from "./GiftItemApi";

const initialState: GiftItemInitialStatus<any> = {
  loading: false,
  manageLoading: false,
  giftItem: [],
  singleGiftItemData: null,
  countGiftItem: 0,
};

const GiftItemSlice = createSlice({
  name: "admin-giftItem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createGiftItemAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createGiftItemAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createGiftItemAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllGiftItemAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllGiftItemAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.giftItem = action.payload.data;
        state.countGiftItem = action.payload.length;
      })
      .addCase(getAllGiftItemAsync.rejected, (state) => {
        state.loading = false;
        state.giftItem = [];
        state.countGiftItem = 0;
      });

    // update
    builder
      .addCase(updateGiftItemAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateGiftItemAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateGiftItemAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteGiftItemAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteGiftItemAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteGiftItemAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // single
    builder
      .addCase(singGiftItemAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleGiftItemData = null;
      })
      .addCase(singGiftItemAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleGiftItemData = action.payload;
      })
      .addCase(singGiftItemAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleGiftItemData = null;
      });
  },
});

export default GiftItemSlice;
