import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  DealerData,
  DealerDataParams,
  GetDealerData,
  GetSingleDealerData,
  StatusUpdateTDO,
} from "../../../types/redux/dealers/dealers";

// create
export const createDealerAsync = createAsyncThunk<
  null,
  PostRequest<DealerData, CommonOthers>
>(
  "admin-dealer/createDealer",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createDealer, data, {
        headers: {
          permissionid,
        },
      });

      reRender(true);
      toast("success", "Dealer create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Dealer create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllDealerAsync = createAsyncThunk<
  GetCommonData<GetDealerData>,
  Params<DealerDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-dealer/getAllDealer", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as DealerDataParams;
  try {
    const data = await AXIOS.get(BASE_API.allDealer, {
      params: { ...rest },

      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.dealers,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Dealer fail");
    return rejectWithValue(error);
  }
});

// update
export const updateDealerAsync = createAsyncThunk<
  null,
  PostRequest<DealerData, GetSingleDataParams>
>(
  "admin-dealer/updateDealer",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateDealer}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Dealer update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Dealer update fail");
      return rejectWithValue(error);
    }
  }
);

// get single dealer
export const singleDealerAsync = createAsyncThunk<
  GetSingleDealerData,
  Params<GetSingleDataParams>
>("admin-dealer/singleDealer", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.singleDealer}/${params?.id}`, {
      headers: {
        permissionid: params?.permissionid,
      },
    });

    return data.data?.dealer;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get single dealer data fail");
    return rejectWithValue(error);
  }
});

// update dealer status
export const updateDealerStatusAsync = createAsyncThunk<
  null,
  PostRequest<StatusUpdateTDO, CommonOthers>
>(
  "admin-dealer/updateStatusDealer",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.dealerStatusUpdate, data, {
        headers: {
          permissionid,
        },
      });

      reRender && reRender(true);
      toast("success", "Dealer status update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Dealer status update fail");
      return rejectWithValue(error);
    }
  }
);
