import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../../types/redux";
import {
  giftAssignData,
  GiftAssignDataParams,
} from "../../../../types/redux/gift/giftAssign";

// create
export const createGiftAssignAsync = createAsyncThunk<
  null,
  PostRequest<giftAssignData, CommonOthers>
>(
  "admin-gift/createGiftAssign",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createGiftAssign, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Gift assign create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Gift assign create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllGiftAssignAsync = createAsyncThunk<
  GetCommonData<giftAssignData>,
  Params<GiftAssignDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-gift/getAllGiftAssign", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as GiftAssignDataParams;

  try {
    const data = await AXIOS.get(BASE_API.allGiftAssign, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.giftAssigns,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get gift assign fail");
    return rejectWithValue(error);
  }
});

// get Valid Package
export const getValidGiftPackageAsync = createAsyncThunk<
  GetCommonData<giftAssignData>,
  Params<GiftAssignDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-gift/getValidGiftPackage", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as GiftAssignDataParams;

  try {
    const data = await AXIOS.get(BASE_API.getValidPackage, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.validGiftPackage,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get gift package fail");
    return rejectWithValue(error);
  }
});

// update
export const updateGiftAssignAsync = createAsyncThunk<
  null,
  PostRequest<giftAssignData, GetSingleDataParams>
>(
  "admin-gift/updateGiftAssign",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateGiftAssign}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Gift assign update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Gift assign update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteGiftAssignAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-gift/deleteGiftAssign", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteGiftAssign}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "Gift assign delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Gift assign delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singGiftAssignAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-gift/singGiftAssignData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.viewGiftAssign}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    return data.data?.giftAssign as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single gift assign get fail");
    return rejectWithValue(error);
  }
});
