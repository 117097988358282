import { App as AntApp, ConfigProvider, notification } from "antd";
import { onMessage } from "firebase/messaging";
import { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import notificationSound from "./assets/audio/notification-sound.wav";
import { Worker } from "@react-pdf-viewer/core";
import CustomNotification from "./components/common/CustomNotification";
import EmptyComponent from "./components/common/EmptyComponent";
import Loader from "./components/common/Loader";
import { generateToken, messaging } from "./helpers/firebaseConfig";
import useApp from "./hooks/useApp";
import useAuthChecked from "./hooks/useAuthChecked";
import usePermissionId from "./hooks/usePermissionId";
import SidebarLayout from "./layouts/SidebarLayout";
import AuthMiddleware from "./middleware/AuthMiddleware";
import { authRouters, publicRouters } from "./routers/routers";
import { setNotificationToken } from "./store/features/auth/loginSlice";
import { allNotificationAsync } from "./store/features/Notification/NotificationApi";
import { singleAllRolePermissionAsync } from "./store/features/UserManage/CreateRole/RolePermissionApi";
import { useAppDispatch, useAppSelector } from "./store/store";

type MSGType = {
  title: string;
  author: string;
  orderId: string;
  image?: string;
};

const App: React.FC = () => {
  useApp();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const { authChecked } = useAuthChecked();
  const { token, login } = useAppSelector((state) => state.login);
  const { singleSidebarLoading, singleSidebarRoleData } = useAppSelector(
    (state) => state.rolePermission
  );
  const notificationPermissionId = usePermissionId("/admin/notification");

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope: ",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed: ", error);
        });
    });
  }

  // Notification with firebase
  useEffect(() => {
    if (!login?._id) return;
    generateToken()
      .then((el) => {
        dispatch(setNotificationToken(el));
      })
      .catch((err) => console.log(err));

    const openNotification = (msg: MSGType) => {
      api.open({
        message: null,
        description: (
          <CustomNotification
            title={msg.title}
            category={msg?.author}
            orderId={msg?.orderId}
            img={msg?.image}
          />
        ),
        duration: 5,
        className: "custom-notification",
      });

      // notification api call for render data
      if (notificationPermissionId) {
        dispatch(
          allNotificationAsync({
            params: { permissionid: notificationPermissionId },
          })
        );
      }
    };

    // const playSound = () => {
    //   const audio = new Audio(notificationSound);
    //   audio.play();
    // };

    onMessage(messaging, (payload) => {
      console.log("Foreground Notification Payload:", payload);
      openNotification({
        title: payload?.notification?.title ?? "",
        author: payload?.notification?.body ?? "",
        orderId: payload?.data?._id ?? "",
        image: payload?.data?.image ?? "",
      });
      // playSound();
    });
  }, [api, dispatch, login?._id, notificationPermissionId]);

  useEffect(() => {
    if (login?.roleId) {
      dispatch(
        singleAllRolePermissionAsync({
          params: {
            id: login?.roleId || "",
            isSidebar: true,
            permissionid: "",
          },
        })
      );
    }
  }, [dispatch, login?.roleId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  // if (authChecked || singleSidebarLoading || loading) {
  //   return <Loader />;
  // }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#e82c4c",
            colorPrimaryText: "#3d4a5c",
            fontFamily: "Inter",
            colorBorder: "#D7DFE9",
          },
          components: {
            Button: {
              colorPrimary: "#0d0d0d",
              colorPrimaryHover: "#222",
            },
            Timeline: {
              tailColor: "#e82c4c",
              itemPaddingBottom: 18,
            },
            Checkbox: {
              colorBorder: "#afbaca",
              colorPrimary: "#e82c4c",
              colorPrimaryHover: "#e82c4c",
            },
            Spin: {
              colorIcon: "#e82c4c",
              colorPrimary: "#e82c4c",
            },
          },
        }}
        renderEmpty={() => <EmptyComponent rootClassName="py-20" />}
      >
        {authChecked || singleSidebarLoading || loading ? (
          <Loader />
        ) : (
          <AntApp>
            {contextHolder}
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to={
                      token && login?.email
                        ? singleSidebarRoleData?.rolePermission?.[0]?.route ||
                          "/admin/dashboard"
                        : "/admin/login"
                    }
                  />
                }
              />
              <Route
                path="/admin"
                element={
                  <Navigate
                    to={
                      token && login?.email
                        ? singleSidebarRoleData?.rolePermission?.[0]?.route ||
                          "/admin/dashboard"
                        : "/admin/login"
                    }
                  />
                }
              />
              <Route path="/login" element={<Navigate to={"/admin/login"} />} />

              {/* Public routers */}
              {publicRouters.map(({ path, element: Element }, idx) => (
                <Route
                  path={path}
                  key={idx + path}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Element />
                    </Suspense>
                  }
                />
              ))}

              {/* Auth sidebar routers */}
              {authRouters.map(({ path, element: Element }, idx) => (
                <Route path={path} element={<SidebarLayout />} key={idx + path}>
                  <Route
                    path={path}
                    element={
                      <AuthMiddleware path={path}>
                        <Suspense fallback={<Loader />}>
                          <Element />
                        </Suspense>
                      </AuthMiddleware>
                    }
                  />
                </Route>
              ))}
            </Routes>
          </AntApp>
        )}
      </ConfigProvider>
    </Worker>
  );
};

export default App;
