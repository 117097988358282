import { Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import MainLogo from "../../../assets/images/login-logo.png";
import { APP_NAME, IMAGE_URL } from "../../../helpers/config";
import useSbu from "../../../hooks/useSbu";
import {
  activePathString,
  pathString,
  stringToArray,
} from "../../../utils/array";
import Image from "../Image";

type MenuItem = Required<MenuProps>["items"][number];

type SidebarItemProps = {
  collapsed: boolean;
  openMenuKeys: string[];
  handleOpenMenu: () => void;
  menuList: MenuItem[];
};

export default function SidebarItems({
  collapsed,
  openMenuKeys,
  handleOpenMenu,
  menuList,
}: SidebarItemProps) {
  let dynamicParams = useParams();
  const location = useLocation();
  const dynamicPath = Object.keys(dynamicParams || {})?.length;
  const sbus = useSbu();
  const logo = sbus?.length === 1 ? IMAGE_URL + sbus?.[0]?.logo : MainLogo;

  function getItem(
    label: React.ReactNode,
    path: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    roles?: string[]
  ): MenuItem {
    return {
      key: pathString(path),
      icon,
      children,
      label: children?.length ? label : <Link to={path}>{label}</Link>,
      roles,
    } as MenuItem;
  }

  // filter allowed menu
  const MenuItems: MenuItem[] = menuList?.map(
    ({ name, path, icon, children }: any) =>
      getItem(
        name,
        path,
        <span dangerouslySetInnerHTML={{ __html: icon }} />,
        children?.length
          ? children?.map(({ name: cdName, path: cdPath, icon: CDIcon }: any) =>
              getItem(
                cdName,
                cdPath,
                <span dangerouslySetInnerHTML={{ __html: CDIcon }} />
              )
            )
          : undefined
      )
  );

  // find active sub menu
  function getActiveMenu(data: any[]) {
    const strArr = stringToArray(location.pathname, "/");
    return data?.find((el) => el?.key === strArr[1])?.children ? true : false;
  }

  return (
    <Sider
      trigger={null}
      theme="dark"
      collapsible
      collapsed={collapsed}
      className="!h-screen !sticky top-0"
      width={312}
    >
      <div
        className={`h-20 flex items-center sticky top-0 z-50 bg-white ${
          collapsed
            ? "pl-0 justify-center text-center"
            : "pl-5 !justify-center text-center"
        }`}
      >
        <Link to="/">
          <Image
            height={collapsed ? 35 : 57}
            width="100%"
            src={logo}
            alt={APP_NAME}
          />
        </Link>
      </div>

      <div className="!overflow-auto h-[calc(100vh-5rem)] !sticky top-0  custom-sidebar">
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[
            activePathString(
              location.pathname,
              "/",
              dynamicPath ? true : false,
              getActiveMenu(MenuItems)
            ),
          ]}
          openKeys={openMenuKeys}
          onOpenChange={handleOpenMenu}
          items={MenuItems}
          className={`sidebar-menu-item ${
            collapsed ? "sidebar_collapsed" : ""
          }`}
        />
      </div>
    </Sider>
  );
}
