import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
  GiftItemData,
  GiftItemParams,
} from "../../../../types/redux/gift/giftItem";

import AXIOS from "../../../../helpers/api";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../../types/redux";

import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";

// create
export const createGiftItemAsync = createAsyncThunk<
  null,
  PostRequest<GiftItemData, CommonOthers>
>(
  "admin-gift/createGiftItem",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createGiftItem, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "GiftItem create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "GiftItem create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllGiftItemAsync = createAsyncThunk<
  GetCommonData<GiftItemData>,
  Params<GiftItemParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-gift/getAllGiftItem", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as GiftItemParams;

  try {
    const data = await AXIOS.get(BASE_API.allGiftItem, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.giftItems,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get gift item fail");
    return rejectWithValue(error);
  }
});

// update
export const updateGiftItemAsync = createAsyncThunk<
  null,
  PostRequest<GiftItemData, GetSingleDataParams>
>(
  "admin-gift/updateGiftItem",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateGiftItem}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Gift item update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Gift item update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteGiftItemAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-gift/deleteGiftItem", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteGiftItem}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "Gift item delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Gift item delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singGiftItemAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-gift/singGiftItemData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.viewGiftItem}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    return data.data?.giftItems as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single gift item get fail");
    return rejectWithValue(error);
  }
});
