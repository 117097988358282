import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import { ErrorMessage, GetCommonData, Params } from "../../../types/redux";
import {
  LedgerDealerParams,
  LedgerParams,
} from "../../../types/redux/ledger/ledger";

// create
export const ledgerDealerAsync = createAsyncThunk<
  GetCommonData<any>,
  Params<LedgerDealerParams>
>("admin-ledger/ledgerDealer", async ({ params }, { rejectWithValue }) => {
  const { data, permissionid, ...rest } = params as LedgerDealerParams;
  try {
    const getDta = await AXIOS.post(BASE_API.ledgerDealer, data, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: getDta.data?.getData,
      length: getDta.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Ledger Dealer fail");
    return rejectWithValue(error);
  }
});

export const getLedgerByDealerAsync = createAsyncThunk<
  GetCommonData<any>,
  Params<LedgerParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-ledger/getLedgerByDealer", async ({ params }, { rejectWithValue }) => {
  const { permissionid, dealerId, sbuId, ...rest } = params as LedgerParams;
  try {
    const data = await AXIOS.get(
      `${BASE_API.getLedgerByDealer}/${dealerId}/${sbuId}`,
      {
        params: { ...rest },

        headers: {
          permissionid,
        },
      }
    );

    return {
      data: data.data?.getData,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get ledger fail");
    return rejectWithValue(error);
  }
});
