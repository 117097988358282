import { useLocation } from "react-router-dom";
import { useAppSelector } from "../store/store";

export default function usePermissionId(route?: string) {
  const location = useLocation();
  const path = route || location.pathname;

  const { permissionList } = useAppSelector((state) => state.app);
  const id = permissionList?.find((el) => el?.route === path);
  return id?._id;
}
