import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import AXIOS from "./api";

const firebaseConfig = {
  apiKey: "AIzaSyAFP5o7eK2nwEpy4vz4xVYKqdx9pzTHEBA",
  authDomain: "agix-dd810.firebaseapp.com",
  projectId: "agix-dd810",
  storageBucket: "agix-dd810.firebasestorage.app",
  messagingSenderId: "358844119772",
  appId: "1:358844119772:web:a4ea5e2e6ea8c8320e9219",
  measurementId: "G-KQW40DSK9N",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAJwNcq73uN7f43KCKJ-nYOediYjryFpgM",
//   authDomain: "project-agi-999.firebaseapp.com",
//   projectId: "project-agi-999",
//   storageBucket: "project-agi-999.firebasestorage.app",
//   messagingSenderId: "402216076764",
//   appId: "1:402216076764:web:ac64e5a835f8daf9533b19",
//   measurementId: "G-77Q604HN1M",
// };

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey:
        "BIZRa8oyLdBUPNfjmcwEb95dgNLb0yKIffuNDMNq-pO4IpAKOlzLfWCormX0v-_hXP3JHv2ZYWWy6tBFKoTPRdE",
    });
    // const token = await getToken(messaging, {
    //   vapidKey:
    //     "BAvtJImzbxJmFrXAUDM-7XLeN2eh2Pgg4MY1L4e8xb3j8G_DuXJMSyMm1gDEPmT3XVLSFGGsQqk9UmK0NiXFKls",
    // });

    try {
      AXIOS.post("/app/order/v1/token-subscribe", {
        fcmToken: token,
        topic: "news",
      });
    } catch (error) {
      console.log(error);
    }

    return token;
  }
};
