import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  TerritoryData,
  TerritoryDataParams,
} from "../../../types/redux/geo/territory";

// create
export const createTerritoryAsync = createAsyncThunk<
  null,
  PostRequest<TerritoryData, CommonOthers>
>(
  "admin-geo/createTerritory",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createTerritory, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Territory create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Territory create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllTerritoryAsync = createAsyncThunk<
  GetCommonData<TerritoryData>,
  Params<TerritoryDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-geo/getAllTerritory", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as TerritoryDataParams;

  try {
    const data = await AXIOS.get(BASE_API.allTerritory, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.territories,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get Territory fail");
    return rejectWithValue(error);
  }
});

// update
export const updateTerritoryAsync = createAsyncThunk<
  null,
  PostRequest<TerritoryData, GetSingleDataParams>
>(
  "admin-geo/updateTerritory",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateTerritory}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Territory update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Territory update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteTerritoryAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-geo/deleteTerritory", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteTerritory}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "Territory delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Territory delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singleTerritoryAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-geo/singleTerritoryData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.viewTerritory}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    return data.data?.territories as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single territories data get fail");
    return rejectWithValue(error);
  }
});
