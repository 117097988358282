import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  CommonParams,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import { DivisionData } from "../../../types/redux/geo/division";

// create
export const createDivisionAsync = createAsyncThunk<
  null,
  PostRequest<Omit<DivisionData, "_id">, CommonOthers>
>(
  "admin-geo/createDivision",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createDivision, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Division create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Division create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllDivisionAsync = createAsyncThunk<
  GetCommonData<DivisionData>,
  Params<CommonParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-geo/getAllDivision", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as CommonParams;

  try {
    const data = await AXIOS.get(BASE_API.allDivision, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.divisions,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get division fail");
    return rejectWithValue(error);
  }
});

// update
export const updateDivisionAsync = createAsyncThunk<
  null,
  PostRequest<DivisionData, GetSingleDataParams>
>(
  "admin-geo/updateDivision",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateDivision}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Division update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Division update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteDivisionAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-geo/deleteDivision", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteDivision}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "Division delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Division delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singDivisionAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-geo/singDivisionData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.viewDivision}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    return data.data?.divisions as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single division data get fail");
    return rejectWithValue(error);
  }
});
