import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  BankListData,
  BankListParams,
} from "../../../types/redux/bank/bankList";

// create
export const createBankListAsync = createAsyncThunk<
  null,
  PostRequest<BankListData, CommonOthers>
>(
  "admin-bank/createBankList",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createBank, data, {
        headers: { permissionid },
      });
      reRender(true);
      toast("success", "Bank create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bank create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllBankListAsync = createAsyncThunk<
  GetCommonData<BankListData>,
  Params<BankListParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-bank/getAllBankList", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as BankListParams;

  try {
    const data = await AXIOS.get(BASE_API.allBank, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.banks,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get bank fail");
    return rejectWithValue(error);
  }
});

// update
export const updateBankListAsync = createAsyncThunk<
  null,
  PostRequest<BankListData, GetSingleDataParams>
>(
  "admin-bank/updateBankList",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateBank}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Bank update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bank update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteBankListAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-bank/deleteBankList", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteBank}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "Bank delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Bank delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singBankListAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-bank/singBankList", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.viewBank}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    return data.data?.banks as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single Bank data get fail");
    return rejectWithValue(error);
  }
});
