import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import { OrdersData, OrdersDataParams } from "../../../types/redux/order/order";

// get all
export const getAllOrderAsync = createAsyncThunk<
  GetCommonData<OrdersData>,
  Params<OrdersDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-order/getAllOrder", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as OrdersDataParams;

  try {
    const data = await AXIOS.get(BASE_API.allOrder, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.orders,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get order data fail");
    return rejectWithValue(error);
  }
});

// update
export const updateOrderItemAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/updateOrderItem",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateOrderItem}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Update fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateOrderAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/updateOrder",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateOrder}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      // toast("success", "Update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Update fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singleOrderDataAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-order/singleOrderData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.viewOrder}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    return data.data as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single order data get fail");
    return rejectWithValue(error);
  }
});

export const orderSentToWarehouseStatusAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/orderSentToWarehouse",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.orderSentToWarehouse}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Order Sent to Warehouse Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order Sent to Warehouse Fail");
      return rejectWithValue(error);
    }
  }
);

export const orderCancelStatusAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/orderCancelStatus",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.orderCancelStatus}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Order Cancel Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order Cancel Fail");
      return rejectWithValue(error);
    }
  }
);

export const orderSentToIccAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/orderSentToIcc",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.orderSentToIcc}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Order Sent to ICC Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order Sent to ICC Fail");
      return rejectWithValue(error);
    }
  }
);

export const orderSentToMdAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/orderSentToMd",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.orderSentToMd}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Order Sent to MD Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order Sent to MD Fail");
      return rejectWithValue(error);
    }
  }
);

export const orderSentToCoAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/orderSentToCo",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.orderSentToCo}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Order Sent to CO Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order Sent to MD Fail");
      return rejectWithValue(error);
    }
  }
);

export const orderSentToSalesAdminAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/orderSentToSalesAdmin",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.orderSentToSalesAdmin}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Order Sent to Sales Admin Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order Sent to Sales Admin Fail");
      return rejectWithValue(error);
    }
  }
);

// new order to warehouse
export const newOrderToWarehouseAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/newOrderToWarehouse",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.newOrderToWarehouse}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Order Sent to Warehouse Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order Sent to Warehouse Fail");
      return rejectWithValue(error);
    }
  }
);

// order update from warehouse
export const orderUpdateWarehouseAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/orderUpdateWarehouse",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.orderUpdateWarehouse}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Order Update Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order Update Fail");
      return rejectWithValue(error);
    }
  }
);

// get data from warehouse
export const getFromWarehouseAsync = createAsyncThunk<any, Params<any>>(
  "admin-order/getFromWarehouse",
  async ({ params }, { rejectWithValue }) => {
    try {
      const { permissionid, warehouseId } = params as any;

      const data = await AXIOS.get(
        `${BASE_API.getFromWarehouse}?warehouseId=${JSON.stringify(
          warehouseId
        )}`,
        {
          // params: { ...rest },
          headers: { permissionid: permissionid },
        }
      );

      return data.data?.getData as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get data from warehouse fail");
      return rejectWithValue(error);
    }
  }
);

// order delivery confirm
export const orderDeliveryConfirmAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/orderDeliveryConfirm",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(
        `${BASE_API.orderDeliveryConfirm}/${id}/update-details/${data?.warehouse?._id}`,
        data,
        {
          headers: { permissionid },
        }
      );

      reRender && reRender(true);
      toast("success", "Order Delivery Confirm Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Order Delivery Confirm Fail");
      return rejectWithValue(error);
    }
  }
);

// Gift to order
export const giftToOrderAsync = createAsyncThunk<
  null,
  PostRequest<any, GetSingleDataParams>
>(
  "admin-order/giftToOrder",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.assignGiftToOrder}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Gift order Confirm Successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Gift order Confirm Fail");
      return rejectWithValue(error);
    }
  }
);
