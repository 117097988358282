import { createSlice } from "@reduxjs/toolkit";

import { MenuData, MenuInitialStatus } from "../../../../types/redux/user/menu";
import {
  createMenuAsync,
  deleteMenuAsync,
  getAllMenuAsync,
  singMenuAsync,
  updateMenuAsync,
} from "./MenuApi";

const initialState: MenuInitialStatus<MenuData, any> = {
  loading: false,
  manageLoading: false,
  sidebarLoading: false,
  viewLoading: false,
  menu: [],
  fullMenu: [],
  sidebarMenu: [],
  countMenu: 0,
  singleMenuData: null,
};

const menuSlice = createSlice({
  name: "admin-user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create
    builder
      .addCase(createMenuAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createMenuAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createMenuAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // get all
    builder
      .addCase(getAllMenuAsync.pending, (state, action) => {
        if (action.meta.arg.params?.isSidebar) {
          state.sidebarLoading = true;
        } else if (!action.meta.arg.params?.isFull) {
          state.loading = true;
        }
      })
      .addCase(getAllMenuAsync.fulfilled, (state, action) => {
        if (action.meta.arg.params?.isFull) {
          state.fullMenu = action.payload?.data;
        } else if (action.meta.arg.params?.isSidebar) {
          // console.log(action.payload?.data);
          state.sidebarMenu = action.payload?.data;
          state.sidebarLoading = false;
        } else {
          state.loading = false;
          state.menu = action.payload?.data;
          state.countMenu = action.payload.length;
        }
      })
      .addCase(getAllMenuAsync.rejected, (state, action) => {
        if (action.meta.arg.params?.isFull) {
          state.fullMenu = [];
        } else if (action.meta.arg.params?.isSidebar) {
          state.sidebarLoading = false;
        } else {
          state.loading = false;
          state.menu = [];
          state.countMenu = 0;
        }
      });
    // get single
    builder
      .addCase(singMenuAsync.pending, (state) => {
        state.viewLoading = true;
        state.singleMenuData = null;
      })
      .addCase(singMenuAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.singleMenuData = action.payload?.data;
      })
      .addCase(singMenuAsync.rejected, (state) => {
        state.viewLoading = false;
        state.singleMenuData = null;
      });

    // update
    builder
      .addCase(updateMenuAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(updateMenuAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(updateMenuAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    // delete
    builder
      .addCase(deleteMenuAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(deleteMenuAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(deleteMenuAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export default menuSlice;
