import { Drawer, Layout } from "antd";
import { useEffect } from "react";
import withSidebar from "../HOC/withSidebar";
import SidebarContent from "../components/common/sidebar/SidebarContent";
import SidebarItems from "../components/common/sidebar/SidebarItems";
import { getAllSbuAsync } from "../store/features/Sbu/SbuApi";
import { useAppDispatch, useAppSelector } from "../store/store";

export type SidebarProps = {
  collapsed: boolean;
  openSidebar: boolean;
  openMenuKeys: string[];
  handleOpenMenu: () => void;
  handleCollapsed: () => void;
  handleShowSidebar: () => void;
  handleCloseSidebar: () => void;
};

const SidebarLayout: React.FC<SidebarProps> = ({
  collapsed,
  openSidebar,
  openMenuKeys,
  handleOpenMenu,
  handleCollapsed,
  handleShowSidebar,
  handleCloseSidebar,
}) => {
  const dispatch = useAppDispatch();
  const { sidebarMenu } = useAppSelector((state) => state.menu);
  const { singleSidebarRoleData } = useAppSelector(
    (state) => state.rolePermission
  );

  const rolePermission = singleSidebarRoleData?.rolePermission;

  // useEffect(() => {
  //   if (rolePermission?.length) {
  //     dispatch(setPermission(rolePermission));
  //   }
  // }, [dispatch, rolePermission]);

  const menuList: any[] = [];

  // sidebarMenu?.forEach((menu) => {
  //   if (menu?.type === "parent-menu") {
  //     menuList.push({
  //       ...menu,
  //       children: [],
  //     });
  //   }

  //   if (menu?.type === "child-menu") {
  //     const findIndex = menuList?.findIndex(
  //       (el) => el?._id === menu?.parentId?._id
  //     );

  //     if (findIndex >= 0) {
  //       menuList[findIndex].children.push(menu);
  //     }
  //   }
  // });
  sidebarMenu?.forEach((menu) => {
    if (menu?.type === "parent-menu") {
      menuList.push({
        ...menu,
        children: [],
      });
    }
  });

  sidebarMenu?.forEach((menu) => {
    if (menu?.type === "child-menu") {
      const findIndex = menuList?.findIndex(
        (el) => el?._id === menu?.parentId?._id
      );

      if (findIndex >= 0) {
        menuList[findIndex].children.push(menu);
      }
    }
  });

  // Filter the menu based on role permissions
  const filterMenu = menuList
    .map((menu) => {
      const filteredChildren = menu.children.filter((child: any) =>
        rolePermission?.some((el: any) => el?.route === child.route)
      );

      if (
        filteredChildren.length > 0 ||
        rolePermission?.some((el: any) => el?.route === menu.route)
      ) {
        return {
          ...menu,
          children: filteredChildren,
        };
      }
      return null;
    })
    .filter(Boolean);

  const showMenuList: any = filterMenu?.map((el) => ({
    name: el?.name,
    path: el?.route,
    icon: el?.icon,
    children: el?.children?.map((item: any) => ({
      name: item?.name,
      path: item?.route,
      icon: item?.icon,
    })),
  }));

  //get sbu
  useEffect(() => {
    dispatch(
      getAllSbuAsync({
        params: {
          pageNo: 0,
          limit: 0,
        },
      })
    );
  }, [dispatch]);

  return (
    <Layout className="min-h-screen">
      <div className="lg:hidden">
        <Drawer
          placement="left"
          closable={false}
          onClose={handleCloseSidebar}
          open={openSidebar}
          key="left"
          contentWrapperStyle={{ width: "auto" }}
          bodyStyle={{ padding: 0 }}
        >
          <SidebarItems
            collapsed={false}
            openMenuKeys={openMenuKeys}
            handleOpenMenu={handleOpenMenu}
            menuList={showMenuList}
          />
        </Drawer>
      </div>
      <div className="hidden lg:block">
        <SidebarItems
          collapsed={collapsed}
          openMenuKeys={openMenuKeys}
          handleOpenMenu={handleOpenMenu}
          menuList={showMenuList}
        />
      </div>

      {/* Sidebar content is main children component, where show children data. This component contains Header items like: Profile and Notification  */}
      <SidebarContent
        collapsed={collapsed}
        handleCollapsed={handleCollapsed}
        handleShowSidebar={handleShowSidebar}
      />
    </Layout>
  );
};

export default withSidebar(SidebarLayout);
