import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  ProductData,
  ProductDataParams,
} from "../../../types/redux/manageProduct/product";

// create
export const createProductAsync = createAsyncThunk<
  null,
  PostRequest<Omit<ProductData, "_id">, CommonOthers>
>(
  "admin-product/createProduct",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createProduct, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Product create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllProductAsync = createAsyncThunk<
  GetCommonData<ProductData>,
  Params<ProductDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-product/getAllProduct", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as ProductDataParams;

  try {
    const data = await AXIOS.get(BASE_API.allProduct, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.products,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get product fail");
    return rejectWithValue(error);
  }
});

// update
export const updateProductAsync = createAsyncThunk<
  null,
  PostRequest<ProductData, GetSingleDataParams>
>(
  "admin-product/updateProduct",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateProduct}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Product update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteProductAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>("admin-product/deleteProduct", async ({ params }, { rejectWithValue }) => {
  try {
    await AXIOS.delete(`${BASE_API.deleteProduct}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    params?.reRender && params?.reRender(true);
    toast("success", "Product delete successfully");
    return null;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", "Product delete fail");
    return rejectWithValue(error);
  }
});

// single
export const singProductAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("admin-product/singProductData", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.viewProduct}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    return data.data?.products as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Single product data get fail");
    return rejectWithValue(error);
  }
});
