import { createSlice } from "@reduxjs/toolkit";

import {
  LedgerListType,
  PaymentInitialStatus,
} from "../../../types/redux/payment/payment";
import {
  approvePaymentAsync,
  getAllLedgerAsync,
  getAllPaymentAsync,
  rejectPaymentAsync,
  singlePaymentAsync,
} from "./DealerPaymentApi";

const initialState: PaymentInitialStatus<any, any, LedgerListType> = {
  loading: false,
  manageLoading: false,
  paymentList: [],
  countPayment: 0,
  singlePaymentData: null,
  ledgerList: null,
  countLedger: 0,
};

const dealerPaymentSlice = createSlice({
  name: "admin-dealerPayment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPaymentAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPaymentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentList = action.payload.data;
        state.countPayment = action.payload.length;
      })
      .addCase(getAllPaymentAsync.rejected, (state) => {
        state.loading = false;
        state.paymentList = [];
        state.countPayment = 0;
      });

    // view
    builder
      .addCase(singlePaymentAsync.pending, (state) => {
        state.manageLoading = true;
        state.singlePaymentData = null;
      })
      .addCase(singlePaymentAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singlePaymentData = action.payload;
      })
      .addCase(singlePaymentAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singlePaymentData = null;
      });
    // update
    builder
      .addCase(approvePaymentAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(approvePaymentAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(approvePaymentAsync.rejected, (state) => {
        state.manageLoading = false;
      });
    // reject
    builder
      .addCase(rejectPaymentAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(rejectPaymentAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(rejectPaymentAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    builder
      .addCase(getAllLedgerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLedgerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.ledgerList = action.payload.data;
        state.countLedger = action.payload.length;
      })
      .addCase(getAllLedgerAsync.rejected, (state) => {
        state.loading = false;
        state.ledgerList = null;
        state.countLedger = 0;
      });
  },
});

export default dealerPaymentSlice;
