import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../../types/redux";
import {
  CreatedUserData,
  GetAllUserParams,
} from "../../../../types/redux/user/createUser";

// create user
export const createUserAsync = createAsyncThunk<
  null,
  PostRequest<CreatedUserData, CommonOthers>
>(
  "create-user/createUser",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createUser, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "User create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "User create fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateUserAsync = createAsyncThunk<
  null,
  PostRequest<CreatedUserData, CommonOthers>
>(
  "create-user/updateUser",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateUser}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "User update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "User update fail");
      return rejectWithValue(error);
    }
  }
);

// get all users
export const getAllUserAsync = createAsyncThunk<
  GetCommonData<any>,
  Params<GetAllUserParams>,
  {
    rejectValue: ErrorMessage;
  }
>("create-user/getAllUser", async ({ params }, { rejectWithValue }) => {
  const { permissionid, ...rest } = params as GetAllUserParams;

  try {
    const data = await AXIOS.get(BASE_API.allUser, {
      params: { ...rest },
      headers: {
        permissionid,
      },
    });

    return {
      data: data.data?.users,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "get all user fail");
    return rejectWithValue(error);
  }
});

// get single user
export const singleUserAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>("create-user/singleUser", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(`${BASE_API.viewUser}/${params?.id}`, {
      headers: { permissionid: params?.permissionid },
    });

    return data.data?.getData as any;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get single user data get fail");
    return rejectWithValue(error);
  }
});
