import { createSlice } from "@reduxjs/toolkit";
import { NotificationInitialStatus } from "../../../types/redux/notification/notification";
import {
  allNotificationAsync,
  createNotificationAsync,
} from "./NotificationApi";

const initialState: NotificationInitialStatus<any> = {
  loading: false,
  manageLoading: false,
  notifications: [],
  countNotifications: 0,
};

const notificationSlice = createSlice({
  name: "admin-notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNotificationAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(createNotificationAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(createNotificationAsync.rejected, (state) => {
        state.manageLoading = false;
      });

    builder
      .addCase(allNotificationAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(allNotificationAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload.data;
        state.countNotifications = action.payload.length;
      })
      .addCase(allNotificationAsync.rejected, (state) => {
        state.loading = false;
        state.notifications = [];
        state.countNotifications = 0;
      });
  },
});

export default notificationSlice;
