import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";
import {
  PoliceStationData,
  PoliceStationDataParams,
} from "../../../types/redux/geo/policeStation";

// create
export const createPoliceStationAsync = createAsyncThunk<
  null,
  PostRequest<PoliceStationData, CommonOthers>
>(
  "admin-policeStation/createPoliceStation",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createPoliceStation, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Police station create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Police station create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllPoliceStationAsync = createAsyncThunk<
  GetCommonData<PoliceStationData>,
  Params<PoliceStationDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-policeStation/getAllPoliceStation",
  async ({ params }, { rejectWithValue }) => {
    const { permissionid, ...rest } = params as PoliceStationDataParams;

    try {
      const data = await AXIOS.get(BASE_API.allPoliceStation, {
        params: { ...rest },
        headers: {
          permissionid,
        },
      });

      return {
        data: data.data?.policeStations,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get police station fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updatePoliceStationAsync = createAsyncThunk<
  null,
  PostRequest<PoliceStationData, GetSingleDataParams>
>(
  "admin-policeStation/updatePoliceStation",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updatePoliceStation}/${id}`, data, {
        headers: {
          permissionid,
        },
      });

      reRender && reRender(true);
      toast("success", "Police station update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Police station update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deletePoliceStationAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>(
  "admin-policeStation/deletePoliceStation",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.deletePoliceStation}/${params?.id}`, {
        headers: {
          permissionid: params?.permissionid,
        },
      });

      params?.reRender && params?.reRender(true);
      toast("success", "Police Station delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Police station delete fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singPoliceStationAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>(
  "admin-policeStation/singPoliceStationData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.viewPoliceStation}/${params?.id}`,
        {
          headers: {
            permissionid: params?.permissionid,
          },
        }
      );

      return data.data?.policeStations as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single police stations data get fail");
      return rejectWithValue(error);
    }
  }
);
