import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CategoryData,
  CategoryDataParams,
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  GetSingleDataParams,
  Params,
  PostRequest,
} from "../../../types/redux";

// create
export const createProductCategoryAsync = createAsyncThunk<
  null,
  PostRequest<CategoryData, CommonOthers>
>(
  "admin-ProductCategory/createProductCategory",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createProductCategory, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Product category create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product category create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllProductCategoryAsync = createAsyncThunk<
  GetCommonData<CategoryData>,
  Params<CategoryDataParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-ProductCategory/getAllProductCategory",
  async ({ params }, { rejectWithValue }) => {
    const { permissionid, ...rest } = params as CategoryDataParams;

    try {
      const data = await AXIOS.get(BASE_API.allProductCategory, {
        params: { ...rest },
        headers: {
          permissionid,
        },
      });

      return {
        data: data.data?.categories,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get product category fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateProductCategoryAsync = createAsyncThunk<
  null,
  PostRequest<CategoryData, GetSingleDataParams>
>(
  "admin-ProductCategory/updateProductCategory",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateProductCategory}/${id}`, data, {
        headers: { permissionid },
      });

      reRender && reRender(true);
      toast("success", "Product category update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Product Category update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteProductCategoryAsync = createAsyncThunk<
  null,
  Params<GetSingleDataParams>
>(
  "admin-ProductCategory/deleteProductCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.deleteProductCategory}/${params?.id}`, {
        headers: { permissionid: params?.permissionid },
      });

      params?.reRender && params?.reRender(true);
      toast("success", "Product category delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "Product category delete fail");
      return rejectWithValue(error);
    }
  }
);

// single
export const singleProductCategoryAsync = createAsyncThunk<
  any,
  Params<GetSingleDataParams>
>(
  "admin-geo/singleProductCategory",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(
        `${BASE_API.singleProductCategory}/${params?.id}`,
        {
          headers: { permissionid: params?.permissionid },
        }
      );

      return data.data?.categories as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get single product category data fail");
      return rejectWithValue(error);
    }
  }
);
