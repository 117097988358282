import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import { PermissionParams } from "../../../../types";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
} from "../../../../types/redux";
import { MenuData } from "../../../../types/redux/user/menu";
import {
  GetAllRoleParams,
  GetRolePermissionData,
  GetRolePermissionSingleParams,
} from "../../../../types/redux/user/rolePermission";

// create
export const createRolePermissionAsync = createAsyncThunk<
  null,
  PostRequest<GetRolePermissionData, CommonOthers>
>(
  "admin-user/createRolePermission",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createRole, data, {
        headers: { permissionid },
      });

      reRender(true);
      toast("success", "Role create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Role permission create fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getAllRolePermissionAsync = createAsyncThunk<
  GetCommonData<GetRolePermissionData>,
  Params<GetAllRoleParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-user/getAllRolePermission",
  async ({ params }, { rejectWithValue }) => {
    const { permissionid, ...rest } = params as GetAllRoleParams;

    try {
      const data = await AXIOS.get(BASE_API.allRole, {
        params: { ...rest },
        headers: {
          permissionid,
        },
      });

      return {
        data: data.data?.roles,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get all role permission fail");
      return rejectWithValue(error);
    }
  }
);

// get all
export const getPermissionAsync = createAsyncThunk<
  GetCommonData<GetRolePermissionData>,
  PermissionParams,
  {
    rejectValue: ErrorMessage;
  }
>("admin-user/getPermission", async ({ permissionid }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.rolePermission, {
      headers: { permissionid },
    });

    return {
      data: data.data?.roles,
      length: data.data?.totalLength,
    };
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get role permission fail");
    return rejectWithValue(error);
  }
});

// get single
export const singleAllRolePermissionAsync = createAsyncThunk<
  any,
  Params<GetRolePermissionSingleParams>
>(
  "admin-user/singleAllRolePermission",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(`${BASE_API.viewRole}/${params?.id}`, {
        headers: {
          permissionid: params?.permissionid,
        },
      });

      return data.data?.role as any;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Single role permission data get fail");
      return rejectWithValue(error);
    }
  }
);

// update
export const updateRolePermissionAsync = createAsyncThunk<
  null,
  PostRequest<MenuData, GetRolePermissionSingleParams>
>(
  "admin-user/updateRolePermission",
  async (
    { data, others: { reRender, id, permissionid } },
    { rejectWithValue }
  ) => {
    try {
      await AXIOS.patch(`${BASE_API.updateRole}/${id}`, data, {
        headers: {
          permissionid,
        },
      });

      reRender && reRender(true);
      toast("success", "Role permission update successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Role permission update fail");
      return rejectWithValue(error);
    }
  }
);

// delete
export const deleteRolePermissionAsync = createAsyncThunk<
  null,
  Params<GetRolePermissionSingleParams>
>(
  "admin-user/deleteRolePermission",
  async ({ params }, { rejectWithValue }) => {
    try {
      await AXIOS.delete(`${BASE_API.deleteRole}/${params?.id}`, {
        headers: {
          permissionid: params?.permissionid,
        },
      });

      params?.reRender && params?.reRender(true);
      toast("success", "role permission delete successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", "role permission delete fail");
      return rejectWithValue(error);
    }
  }
);
