import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetCommonData,
  Params,
  PostRequest,
} from "../../../types/redux";
import { NotificationData } from "../../../types/redux/notification/notification";

// create
export const createNotificationAsync = createAsyncThunk<
  null,
  PostRequest<NotificationData, CommonOthers>
>(
  "admin-notification/createNotification",
  async ({ data, others: { reRender, permissionid } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createNotification, data, {
        headers: {
          permissionid,
        },
      });

      reRender(true);
      // toast("success", "Notification create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Notification send fail");
      return rejectWithValue(error);
    }
  }
);

export const allNotificationAsync = createAsyncThunk<
  GetCommonData<any>,
  Params<{ permissionid?: string }>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-notification/allNotification",
  async ({ params }, { rejectWithValue }) => {
    try {
      const data = await AXIOS.get(BASE_API.allNotification, {
        headers: {
          permissionid: params?.permissionid,
        },
      });

      return {
        data: data.data?.getData,
        length: data.data?.totalLength,
      };
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get Notification fail");
      return rejectWithValue(error);
    }
  }
);
